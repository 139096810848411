<template>
	<WebcamStream />
</template>

<script>
import WebcamStream from "./components/WebcamStream.vue";

export default {
	components: {
		WebcamStream,
	},
};
</script>

<style>
*,
*::before,
*::after {
	box-sizing: border-box;
}
body {
	margin: 0;
	font-family: sans-serif;
}
</style>
