<template>
	<div>

		<br>
		<input type="radio" id="subtotal" name="subtotal" value="subtotal" v-model="detectText">
		<label for="subtotal">Subtotal</label>
		<input type="radio" id="amount" name="subtotal" value="amount" v-model="detectText">
		<label for="amount">Amount</label>

		<br>
		<br>

		<!-- add tippercentageToggle -->
		<input type="radio" id="tip20" name="tippercentage" value="20" v-model="tipPercentage">
		<label for="tip20">20%</label>
		<input type="radio" id="tip18" name="tippercentage" value="18" v-model="tipPercentage">
		<label for="tip18">18%</label>
		<input type="radio" id="tip15" name="tippercentage" value="15" v-model="tipPercentage">
		<label for="tip15">15%</label>

		<br>

		<div style="position: relative; width: 100vw;" v-if="!info">
			<div :style="indicatorStyle"></div>
			<video ref="webcam" id="webcam" autoplay style="width: 100vw">
			</video>
		</div>

		<br>

		<div v-if="info">
			<div>Subtotal: {{info.subTotal}}</div>
			<div>Tip %: {{info.tipPercentage}}</div>
			<div>Tip: {{info.tip}}</div>
			<div>Total: {{info.total}}</div>
		</div>
		<div v-else>
			{{lastDetected}}
		</div>

		<img :src="latestScreenshot" />

	</div>
</template>

<script>
const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export default {
	data(){
		return{
			subTotal: null,
			detectText: 'subtotal',
			tipPercentage: 20,
			lastDetected: '',
			loaded: false,
			latestScreenshot: '',
		}
	},
	async mounted() {
		await this.streamVideo();
		await sleep(500);
		this.detectTextFromWebcam();

		this.loaded = true;
	},
	computed: {
		info(){
			if(!this.subTotal){
				return null;
			}
			const tipPercentage = parseInt(this.tipPercentage);
			return {
				subTotal: this.subTotal,
				tipPercentage,
				tip: this.subTotal * (tipPercentage / 100),
				total: this.subTotal * (1 + tipPercentage / 100),
			}
		},
		indicatorStyle(){
			if(!this.loaded){
				return ''
			}
			const sx = this.$refs.webcam.clientWidth / 10;
			const sy = this.$refs.webcam.clientHeight / 4;
			const sWidth = this.$refs.webcam.clientWidth / 1.25;
			const sHeight = this.$refs.webcam.clientHeight / 2;
			return {
				position: 'absolute',
				border: '3px solid red',
				opacity: 0.5,
				width: sWidth + 'px',
				height: sHeight + 'px',
				top: sy + 'px',
				left: sx + 'px',
			}
		},
		cutoutRanges(){
			if(!this.loaded){
				return {}
			}
			const sWidth = this.$refs.webcam.videoWidth / 1.25;
			const sHeight = this.$refs.webcam.videoHeight / 2;
			return {
				sx: this.$refs.webcam.videoWidth / 10,
				sy: this.$refs.webcam.videoHeight / 4,
				sWidth: sWidth,
				sHeight: sHeight,
				dx: 0,
				dy: 0,
				dWidth: sWidth,
				dHeight: sHeight,
			}
		}
	},
	methods: {
		async streamVideo() {
			try {
				const stream = await navigator.mediaDevices.getUserMedia({
					video: {facingMode: {exact: 'environment'}},
				});
				this.$refs.webcam.srcObject = stream;
			} catch (err) {
				console.error("Error accessing the webcam: ", err);
			}
		},
		async getScreenshotFromVideo(){
			const canvas = document.createElement("canvas");
			canvas.width = this.$refs.webcam.videoWidth;
			canvas.height = this.$refs.webcam.videoHeight;

			// let's a part of the image (the center horizontal rectangle)
			const {sx, sy, sWidth, sHeight, dx, dy, dWidth, dHeight} = this.cutoutRanges;

			canvas
				.getContext("2d")
				.drawImage(this.$refs.webcam, sx, sy, sWidth, sHeight, dx, dy, dWidth, dHeight);
			return canvas.toDataURL("image/png");
		},
		async detectTextFromWebcam(){

			await sleep(500);

			const screenshot = await this.getScreenshotFromVideo();

			// add the screenshot to the page
			this.latestScreenshot = screenshot;

			// eslint-disable-next-line
			const res = await Tesseract.recognize(
				screenshot,
				'eng'
			)
			const text = res.data.text;
			// console.log(text);
			this.lastDetected = text;

			const subTotal = this.detectSubtotalFromText(text);
			if(subTotal){
				console.log('Subtotal detected: ', subTotal);
				this.subTotal = subTotal;
				return;
			}

			await this.detectTextFromWebcam();
		},
		detectSubtotalFromText(text){
			const textLines = text.split('\n');
			for(const line of textLines){
				if(line.toLowerCase().includes(this.detectText)){
					const subtotal = line.match(/\d+\.\d+/);
					if(subtotal){
						return subtotal[0];
					}
				}
			}
			return null;
		},
	},
};
</script>
